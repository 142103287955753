@import 'variables';

.newsletter-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: $standardPadding;

  input[type="email"] {
    box-sizing: border-box;
    padding: 0 1rem;
    height: 2.5rem;
    border: 1px solid $primaryColor;
    width: 100%;
    margin-bottom: 1rem;
    max-width: 400px;

    @media (min-width: 600px) {
      margin-bottom: auto;
    }
  }

  input[type="submit"] {
    width: 100%;
    max-width: 200px;
  }
}
