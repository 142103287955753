@import 'variables';

footer {
  margin-top: $standardPadding;
  z-index: $elevation0;
  text-align: center;
  width: 100%;

  @media (min-width: $desktopMinWidth) {
    a {
      margin-right: $standardPadding;
    }
  }
}
