@import 'variables';

.navigation {
  padding: 8px 0;
  margin: 2rem 1rem;
  text-align: center;

  border-top: 2px solid $secondaryColor;
  border-bottom: 2px solid $secondaryColor;
  box-sizing: border-box;

  overflow: auto;

  a {
    transition: background-color 300ms ease-in;
  }

  a, a:hover {
    text-decoration: none;
  }

  a:hover {
    background-color: lavenderblush;
  }

  .menu-icon {
    display: none;
    font-size: 1.3rem;
  }
}

.navigation-links {
  display: flex;
  align-items: center;
  font-family: $capsFont;
  font-size: 0.9rem;
  vertical-align: middle;
  margin: auto;
  width: fit-content;
}

.navigation-links,
.social-links {

  a+a,
  button {
    margin-left: 1rem;
  }
}

.social-links {
  display: flex;
  align-items: center;
}

.social-links a:not(.button) {
  line-height: 100%;
  font-size: 1.1rem;
}

.navigation-link,
.social-link {
  display: block;
}

.social-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.subscribe-button {
  display: inline-flex;
  align-items: center;
  font-size: 0.9rem;
}

.masthead {
  width: fit-content;
  margin: 3rem auto;
  padding: 0 1rem;

  a {
    transition: background-color 300ms ease-in;
  }

  a::after {
    display: none;
  }

  a:hover {
    background-color: lavenderblush;
  }
}

.letterhead {
  font-family: $capsFont;
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
  display: block;
  text-decoration: none;
}

.byline {
  font-family: $capsFont;
  color: $accentColor;
  font-size: 0.8rem;
  text-align: center;
}