// Font variables
$bodyFont: valkyrie_a, Georgia, Didot, serif; 
$headerFont: $bodyFont;
$capsFont: valkyrie_a_caps, valkyrie_a, Georiga, Didot, serif;
$headerFontWeight: bold;
$bodyLineSpacing: 150%;
$monospaceFont: "Fira Mono", monospace;

// Colors
$primaryColor: #B31B1B;
$primaryColorLighter: #B31B1B11;
$secondaryColor: #2a2a2a;
$tertiaryColor: #FFFFFF;
$textColor: $secondaryColor;
$backgroundColor: $tertiaryColor;
$captionColor: #5c5c5c;
$accentColor: #9fa0ac;

// Link variables
$linkHoverColor: lavenderblush;

// Size variables
$bodyMaxWidth: 700px;
$standardPadding: 1rem;

// Animation variables
$standardTransition: all 60ms;

// Device breakpoints
$tabletMinWidth: 500px;
// We need extra space on the margins for an absolutely position header and
// footer.
$desktopMinWidth: $bodyMaxWidth + 500px;

// Elevation levels
$elevation0: 0;
// Elevation 0 has no shadow.
$elevation1: 1;
$elevation1BoxShadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$elevation2: 2;
$elevation2BoxShadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
$elevation3: 3;
$elevation3BoxShadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
