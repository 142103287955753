@import 'common';

.post-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

.post-list-content {
  max-width: 90rem;
  margin: 0 $standardPadding;
  columns: 1;
  column-gap: 3rem;
  column-rule: 2px solid gainsboro;

  @media screen and (min-width: 40rem) {
    columns: 2;
  }

  @media screen and (min-width: 60rem) {
    columns: 3;
  }

  @media screen and (min-width: 80rem) {
    columns: 4;
  }

  .post-item:not(:first-child) {
    margin-top: 2rem;
  }
}

.post-link {
  display: inline;
  hyphens: auto;
  color: inherit;
  text-decoration: none;
  margin: 0.3rem 0;
  font-family: $capsFont;
  font-weight: bold;
  transition: background-color 300ms ease-in;
}

a.post-link:hover {
  background-color: lavenderblush;
}

.post-link::after {
  display: none;
}

.post-excerpt {
  font-size: 0.9rem;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.post-item .post-date {
  margin-top: 0.2rem;
}

.post-date {
  color: $captionColor;
  font-size: 0.7rem;
}

.post-item img {
  float: left;
  clear: left;
  width: 8rem;
  max-height: 8rem;
  object-fit: contain;
  padding-right: 1rem;
  padding-bottom: 0.2rem;
}
