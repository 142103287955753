@import 'variables';
@import 'header';
@import 'footer';
@import 'newsletter';

@font-face {
  font-family: valkyrie_a;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/valkyrie-a/valkyrie_a_regular.woff2') format('woff2');
}

@font-face {
  font-family: valkyrie_a;
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/valkyrie-a/valkyrie_a_italic.woff2') format('woff2');
}

@font-face {
  font-family: valkyrie_a;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/valkyrie-a/valkyrie_a_bold.woff2') format('woff2');
}

@font-face {
  font-family: valkyrie_a;
  font-style: italic;
  font-weight: bold;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/valkyrie-a/valkyrie_a_bold_italic.woff2') format('woff2');
}

@font-face {
  font-family: valkyrie_a_caps;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts//valkyrie-a/valkyrie_a_caps_regular.woff2') format('woff2');
}

@font-face {
  font-family: valkyrie_a_caps;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/valkyrie-a//valkyrie_a_caps_bold.woff2') format('woff2');
}

html {
  font-size: 18px;
  font-family: $bodyFont;
  color: $textColor;
  background-color: $backgroundColor;
  font-feature-settings: "kern";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;

  @media all and (max-width: $tabletMinWidth) {
    font-size: 18px;
  }
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family: $bodyFont;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

p, li {
  line-height: 1.45;
}

figure {
  margin: 2rem 0;
}

figure img {
  vertical-align: middle;
}

figure.three-images figcaption, figure.two-images figcaption, figure.full-width figcaption, figure.medium-width figcaption {
  margin-top: 1rem;
}

figure.three-images img {
  width: calc(100% / 3);
  height: auto;
}

figure.two-images img {
  width: 50%;
  height: auto;
}

figure.medium-width img {
  max-width: $bodyMaxWidth * 0.6;
}

figure.small-width figcaption {
  margin-top: 1rem;
}

figure.small-width img {
  display: block;
  max-width: 50%;
  height: auto;
  margin: auto;
}

.images-container {
  display: flex;
  align-items: center;

  img {
    height: fit-content;
  }
}

figcaption {
  font-size: 0.9rem;
  color: $captionColor;

  .source-link {
    font-family: $capsFont;
    font-style: initial;
  }

  .source-link:after {
    margin-top: -2px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $headerFont;
  font-weight: $headerFontWeight;
  margin: 2rem 0;
}

h1 {
  font-size: 1.2rem;
}

h2 {
  font-size: 1.1rem;
}

h3 {
  font-size: 1rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 1rem;
}

pre {
  font-family: $monospaceFont;
  padding: $standardPadding 0;
  white-space: pre;
  overflow: auto;
  max-width: 100%;
  display: block;
  box-sizing: border-box;
  font-size: 0.8rem;
  line-height: 1.5;
}

:not(pre) > code {
  font-size: initial;
  background: #f6f6f6;
}

pre {
  font-size: 0.7rem;
}

hr {
  border: 1px solid gainsboro;
}

input {
  font-family: inherit;
}

blockquote {
  // border-left: 4px solid gainsboro;
  box-sizing: border-box;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.2rem 1rem;
  background-color: whitesmoke;
}

a {
  text-decoration-color: gainsboro;
  transition: all ease-in 0.3s;
  text-decoration-thickness: 0.1em;
  text-underline-offset: 0.2em;
}

a:hover {
  text-decoration-color: $textColor;
}

a:link, a:visited {
  color: inherit;
}

input[type="submit"], button, a.button {
  border: 1px solid $accentColor;
  box-sizing: border-box;
  padding: 0 1rem;
  height: 1.6rem;
  background-color: $tertiaryColor;
  color: $secondaryColor;
  font-weight: bold;
  font-family: $capsFont;
  vertical-align: middle;
}

.button:hover {
  border-radius: initial;
  color: $tertiaryColor;
  background-color: $secondaryColor !important;
}
